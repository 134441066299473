import * as React from "react"
import { FC, useContext, useEffect } from "react"
import styled from "styled-components"
import {
  LoginContext,
  PasswordResetFormWrapper,
} from "social-supermarket-components"
import Header from "../social-supermarket/components/header/Header"
import { isRunTime } from "../social-supermarket/util/generalUtil"
import { navigate } from "gatsby"
import Tile from "../social-supermarket/components/dashboard/Tile"
import GLink from "../gatsby/GLink"
import SEO from "../social-supermarket/components/Seo"
import TeaserBackground from "../social-supermarket/components/TeaserBackground"
import { getConfig } from "../config"
import { colors } from "social-supermarket-model"

interface Props {}

const Container = styled.div`
  background-color: ${colors.lightGray};
`
const BottomContainer = styled.div`
  min-height: 100vh;
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;
`

const FormContainer = styled(Tile)`
  width: 100%;
  max-width: 500px;
  z-index: 1;
`
const Links = styled.div`
  font-size: 0.8em;
  margin-top: 20px;
`

const Register: FC<Props> = ({}) => {
  const { user, initialising, loggingOut } = useContext(LoginContext)

  useEffect(() => {
    if (isRunTime() && user) {
      navigate("/")
    }
  }, [user])

  const handleSuccess = () => {
    navigate("/")
  }

  return (
    <Container>
      <SEO title={"Reset Password"} description={""} />
      <Header />
      <TeaserBackground />
      <BottomContainer>
        <FormContainer>
          <PasswordResetFormWrapper baseUrl={getConfig().baseUrl} onSuccess={handleSuccess} />
          <Links>
            <GLink to={"/"}>Go Back</GLink>
          </Links>
        </FormContainer>
      </BottomContainer>
    </Container>
  )
}

export default Register
